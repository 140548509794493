import { Item, List } from "react-dsgov";
import { IArtigoPortal, IRegistrosArtigosCategoria } from "../../../interfaces/IRegistrosArtigosCategoria";
import ListaArtigos from "./ListaArtigos/ListaArtigos";
import styles from "./MenuLateralCategoria.module.scss";
import classNames from "classnames";
export interface MenuLateralCategoriaProps {
    registrosCategoria: IRegistrosArtigosCategoria;
    codigoCategoria: string;
    codigoArtigoAtual?: string;
    urlAtual?: string;
}

const MenuLateralCategoria: React.FC<MenuLateralCategoriaProps> = ({ registrosCategoria, codigoCategoria, codigoArtigoAtual, urlAtual }) => {
    const registrosCategoriaOrdenados = [...registrosCategoria.registros].sort((a : IArtigoPortal, b : IArtigoPortal) => Number(b.autorizado) - Number(a.autorizado));
    return (
        <List>
            {registrosCategoriaOrdenados.map((artigoPortal : IArtigoPortal)  =>
                <Item 
                    className={classNames({[styles.inativo] : !artigoPortal.autorizado})}
                    showDividerAfter 
                    collapsable 
                    initiallyExpanded 
                    subItems={
                        <ListaArtigos 
                            artigos={artigoPortal.artigos} 
                            nivel={1} 
                            codigoCategoria={codigoCategoria} 
                            codigoArtigo={artigoPortal.codigo} 
                            codigoArtigoAtual={codigoArtigoAtual} 
                            urlAtual={urlAtual} 
                        />} 
                    key={artigoPortal.id}
                    >
                        {artigoPortal.nome}
                    </Item>
            )}
        </List>
    )
}

export default MenuLateralCategoria;