import { Card, Col, Container, Item, List, Row } from "react-dsgov";
import { Link } from 'react-router-dom';
import styles from './Principal.module.scss'
import { useEffect, useState } from "react";
import classNames from "classnames";
import useBusca from "../../hooks/useBusca";
import IDestaque from "../../interfaces/IDestaque";
import IMaisAcessado from "../../interfaces/IMaisAcessado";
import useGeraLink from "../../hooks/useGeraLink";
import useCatalogoService from "../../hooks/useCatalogoService";
import imgDev from "./../../images/dev2.png";
import { Helmet } from "react-helmet";
import useSubtituloState from "../../state/hooks/useSubtituloState";



const Principal: React.FC = () => {
    const catalogoService = useCatalogoService();
    const busca = useBusca();
    const [searchTerm, setSearchTerm] = useState("");
    const [destaques, setDestaques] = useState<IDestaque[]>([]);
    const [maisAcessados, setMaisAcessados] = useState<IMaisAcessado[]>([]);
    const geraLink = useGeraLink();

    const [,setSubtitulo] = useSubtituloState();

    useEffect(() => {
        setSubtitulo("Principal");
    }, [setSubtitulo])

    const handleBuscaKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            busca(searchTerm);
        }
    }

    useEffect(() => {
        catalogoService.getDestaques().then((destaques) => setDestaques(destaques));
        catalogoService.getMaisAcessados().then((maisAcessados) => setMaisAcessados(maisAcessados));
    }, [catalogoService]);

    return (
        <Container>
            <Helmet>
                <title>Portal do Conhecimento do Desenvolvedor</title>
            </Helmet>
            <Card className={styles['card-busca-principal']}>
                <Card.Content>
                    <div className={classNames("br-input", "has-icon")}>
                        <input
                            id={`searchbox-principal`}
                            className={styles["input-busca-principal"]}
                            type="text"
                            placeholder="O que você procura?"
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                            onKeyDown={handleBuscaKeyDown}
                        />
                        <button
                            className={classNames("br-button", "circle", "large", styles["button-busca-principal"])}
                            type="button"
                            aria-label="Pesquisar"
                            onClick={() => { busca(searchTerm) }}
                        >
                            <i className="fas fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                </Card.Content>
            </Card>
            <Row>
                <Col>
                    <img className={styles.banner} src={imgDev} alt="Mãos em teclados." />
                </Col>
            </Row>
            
            <Row className={styles["destaques-mais-acessados"]}>
                <Col>
                    <div className={styles["tile-header"]}><h3><i className={classNames("fas", "fa-fire", styles.icone)}></i>Mais acessados</h3></div>
                    <Card className={styles["card"]}>
                        <List>
                            {maisAcessados.map((item, index) =>
                                <Item  showDividerAfter={index < maisAcessados.length - 1} key={item.codigo} className={styles.item}><span className={styles.ranking}>{index + 1}</span><Link to={geraLink(item.codigo, item.tipo)}><i className={classNames(styles.icone, item.icone)}></i>{item.nome}</Link></Item>
                            )}
                        </List>
                    </Card>
                </Col>
                <Col>
                    <div className={styles["tile-header"]}><h3><i className={classNames("fas", "fa-star", styles.icone)}></i>Destaques</h3></div>
                    <Card className={styles["card"]}>
                        <List>
                            {destaques.map((item, index) =>
                                <Item showDividerAfter={index < destaques.length - 1} className={styles.item} key={item.codigo}><Link to={geraLink(item.codigo, item.tipo)}><i className={classNames(styles.icone, item.icone)}></i> {item.nome}</Link></Item>
                            )}
                        </List>
                    </Card>
                </Col>
            </Row>
            
        </Container>

    );
}

export default Principal;