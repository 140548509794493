import { useEffect, useState } from "react";
import { Avatar, Button, Header } from "react-dsgov";
import { useNavigate } from "react-router";
import useBusca from "../../hooks/useBusca";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { SigninRedirectArgs } from "oidc-client-ts";
import useSubtituloState from "../../state/hooks/useSubtituloState";
import useCatalogoService from "../../hooks/useCatalogoService";

export interface ISiteHeaderProps {
    showSearchBar?: boolean
}

const SiteHeader : React.FC<ISiteHeaderProps> = ({showSearchBar = true}) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [urlAvatar, setUrlAvatar] = useState("")
    const catalogoService = useCatalogoService();
    const [subtitulo, ] = useSubtituloState();
    const busca = useBusca();
    const navigate = useNavigate();

    const auth = useAuth();
    
    useEffect(() => {
        catalogoService.getAvatar().then((url) => setUrlAvatar(url));
    }, [auth.isAuthenticated]);

    return (
        <Header 
            urlLogo="https://www.gov.br/++theme++padrao_govbr/img/govbr-logo-large.png" 
            systemName="Tesouro Nacional" 
            title="Portal do Conhecimento do Desenvolvedor" 
            subTitle={subtitulo}
            showMenuButton={false}

            quickAccessLinks={[
                {label: 'Sobre o Portal', href: () => {navigate("/sobre")}},
                {label: 'Documentações', href: () => {navigate("/docs")}},
                {label: 'Catálogo de Componentes', href: () => {navigate("/catalogo-componentes")}},
                {label: 'Copilot', href: () => {navigate("/copilot")}},
                //{label: 'Comunidade', href: () => {navigate("/comunidade")}},
            ]}

            /*
            features={[
                {label: 'Funcionalidade 1', icon: 'fas fa-chart-bar', href: 'https://google.com/'},
                {label: 'Funcionalidade 2', icon: 'fas fa-headset', onClick: () => {alert('Clicou!');} },
                {label: 'Funcionalidade 3', icon: 'fas fa-comment'},
                {label: 'Funcionalidade 4', icon: 'fas fa-adjust'},
            ]}
            */

            loggedIn={auth.isAuthenticated}
            onClickLogin={() => {
                auth.signinRedirect({redirect_uri: window.location.href});
            }}

            onSearch={(searchTerm) => {
                busca(searchTerm);
            }}

            showSearchBar={showSearchBar}

            avatar={
                <>
                    <Avatar imageSrc={urlAvatar} />
                    <Button 
                        mx={2} 
                        primary
                        onClick={() => {auth.signoutRedirect({post_logout_redirect_uri: window.location.href.replace(window.location.search, '')})}}
                    >
                        <i className="fas fa-sign-out-alt"></i> Sair
                    </Button>
                </>}
        />
    );
}

export default SiteHeader;