import { AuthContextProps } from "react-oidc-context";
import IComponente, { ICategoria, ITag, ITecnologia } from "../interfaces/IComponente";
import IDestaque from "../interfaces/IDestaque";
import IMaisAcessado from "../interfaces/IMaisAcessado";
import IRegistros from "../interfaces/IRegistros";
import { IResultadosBusca } from "../interfaces/IResultadoBusca";
import ILogComponente from "../interfaces/ILogComponente";
import ICategoriaDocumentacao from "../interfaces/ICategoriaDocumentacao";
import { IRegistrosArtigosCategoria } from "../interfaces/IRegistrosArtigosCategoria";
import IDadosArtigo from "../interfaces/IDadosArtigo";
import ILinkAmigavel from "../interfaces/ILinkAmigavel";
import ITema from "../interfaces/ITema";
import useUrlBasica from "../hooks/useUrlBasica";
export default class CatalogoService {
    auth : AuthContextProps;
    options : any = {};
    urlBasica : string = useUrlBasica();

    constructor(auth : AuthContextProps) {
        
        this.auth = auth;
        if(this.auth.isAuthenticated) {
            console.log(`Bearer ${this.auth.user?.access_token}`);
            this.options = {headers: {"Authorization": `Bearer ${this.auth.user?.access_token}`}};
        }   
    }

    private updateAuthorizationHeader() {
        if(this.auth.isAuthenticated) {
            this.options.headers = {"Authorization": `Bearer ${this.auth.user?.access_token}`};
            
            console.log(this.options);
        }
    }

    private getQueryStringPage(page: number | undefined) {
        let queryStringPage = "";

        if (page) {
            queryStringPage = `&page=${page}`;
        }
        return queryStringPage;
    }

    private substituiUrlsImagens(html : string)  {
        if(html) {
            html = html.replaceAll("\"cosis/galeria/imagem", "\"https://sisweb.tesouro.gov.br/apex/cosis/galeria/imagem");
            html = html.replaceAll("\"cosis/helpo/imagem", "\"https://sisweb.tesouro.gov.br/apex/cosis/helpo/imagem");
            html = html.replaceAll("\"cosis_public.obtem_imagem?", "\"https://sisweb.tesouro.gov.br/apex/cosis_public.obtem_imagem?");
        }
        return html;
    }
    
    public async getComponentes(categorias? : ICategoria[], tecnologias? : ITecnologia[], tags? : ITag[], temas?: ITema[], page? : number) : Promise<IComponente[]> {
        this.updateAuthorizationHeader();
        
        // Monta string com os filtros selecionados
        const filtroTecnologias = tecnologias?.filter(tecnologia => tecnologia.selecionado).map(tecnologia => tecnologia.id.toString()).join(':');
        const filtroCategorias = categorias?.filter(categoria => categoria.selecionado).map(categoria => categoria.id.toString()).join(':');
        const filtroTags = tags?.filter(tag => tag.selecionado).map(categoria => categoria.id.toString()).join(':');
        const filtroTemas = temas?.filter(tema => tema.selecionado).map(tema => tema.id.toString()).join(':');

        let queryStringPage = this.getQueryStringPage(page);

        const url = `${this.urlBasica}v1/portal-conhecimento/custom/componentes?tecnologias=${filtroTecnologias ? filtroTecnologias : ""}&categorias=${filtroCategorias ? filtroCategorias : ""}&tags=${filtroTags ? filtroTags : ""}&temas=${filtroTemas ? filtroTemas : ""}${queryStringPage}`;
        console.log(url);

        return new Promise<IComponente[]>((resolve, reject) => {
            fetch(url, this.options)
                .then(response => response.json() as Promise<IRegistros<IComponente>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    

    public async getComponenteCodigo(codigo: string) : Promise<IComponente> {
        this.updateAuthorizationHeader();

        const url = `${this.urlBasica}v1/portal-conhecimento/custom/componentes?codigo=${codigo}`;
        return new Promise<IComponente>((resolve, reject) => {
            fetch(url, this.options)
                .then(response => response.json() as Promise<IRegistros<IComponente>>)
                .then(data => {
                    const componente = data.registros[0];
                    componente.sobre = this.substituiUrlsImagens(componente.sobre);
                    componente.comoComecar = this.substituiUrlsImagens(componente.comoComecar);
                    componente.documentacao = this.substituiUrlsImagens(componente.documentacao);
                    
                    resolve(componente);
                });
        });
    }

    public async getTecnologias() : Promise<ITecnologia[]> {
        this.updateAuthorizationHeader();

        return new Promise<ITecnologia[]>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/tecnologias`, this.options)
                .then(response => response.json() as Promise<IRegistros<ITecnologia>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getCategorias() : Promise<ICategoria[]> {
        this.updateAuthorizationHeader();

        return new Promise<ICategoria[]>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/categorias`, this.options)
                .then(response => response.json() as Promise<IRegistros<ICategoria>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getTags() : Promise<ITag[]> {
        this.updateAuthorizationHeader();

        return new Promise<ITag[]>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/tags`, this.options)
                .then(response => response.json() as Promise<IRegistros<ITag>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async busca(termo: string, pagina?: number) : Promise<IResultadosBusca> {
        this.updateAuthorizationHeader();

        let queryStringPage = this.getQueryStringPage(pagina);

        return new Promise<IResultadosBusca>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/busca?termo=${encodeURIComponent(termo)}${queryStringPage}`, this.options)
                .then(response => response.json() as Promise<IResultadosBusca>)
                .then(data => {
                    resolve(data);
                });
        });
    }

    public async getDestaques() : Promise<IDestaque[]> {
        this.updateAuthorizationHeader();

        return new Promise<IDestaque[]>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/destaques`, this.options)
                .then(response => response.json() as Promise<IRegistros<IDestaque>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getMaisAcessados() : Promise<IMaisAcessado[]> {
        this.updateAuthorizationHeader();

        return new Promise<IMaisAcessado[]>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/mais-acessados`, this.options)
                .then(response => response.json() as Promise<IRegistros<IMaisAcessado>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getHistoricoComponente(idComponente : number) : Promise<ILogComponente[]> {
        this.updateAuthorizationHeader();

        return new Promise<ILogComponente[]>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/versoes-componente?idComponente=${idComponente}`, this.options)
                .then(response => response.json() as Promise<IRegistros<ILogComponente>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getCategoriasDocumentacoes() : Promise<ICategoriaDocumentacao[]> {
        this.updateAuthorizationHeader();

        return new Promise<ICategoriaDocumentacao[]>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/categorias-documentacoes`, this.options)
                .then(response => response.json() as Promise<IRegistros<ICategoriaDocumentacao>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getRegistrosArtigosCategoria(codigoCategoria : String) : Promise<IRegistrosArtigosCategoria> {
        this.updateAuthorizationHeader();

        return new Promise<IRegistrosArtigosCategoria>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/artigos-documentacoes?codigoCategoria=${codigoCategoria}`, this.options)
                .then(response => response.json() as Promise<IRegistrosArtigosCategoria>)
                .then(data => {
                    resolve(data);
                });
        });
    }

    public async getArtigo(codigoArtigo : String, codigoCategoria : String, url: String) : Promise<IDadosArtigo> {
        this.updateAuthorizationHeader();

        return new Promise<IDadosArtigo>((resolve, reject) => {
            console.log(`${this.urlBasica}v1/portal-conhecimento/custom/artigo-documentacao?codigoArtigo=${codigoArtigo}&codigoCategoria=${codigoCategoria}&url=${url}`);
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/artigo-documentacao?codigoArtigo=${codigoArtigo}&codigoCategoria=${codigoCategoria}&url=${url}`, this.options)
                .then(response => response.json() as Promise<IDadosArtigo>)
                .then(data => {
                    resolve(data);
                });
        });
    }

    public async getLinksAmigaveisArtigos(idsArtigos : number[]) : Promise<ILinkAmigavel> {
        this.updateAuthorizationHeader();

        const options = {...this.options};
        options.method = "POST";
        options.body = JSON.stringify(idsArtigos);

        return new Promise<ILinkAmigavel>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/urls-artigos-manuais`, options)
                .then(response => response.json() as Promise<ILinkAmigavel>)
                .then(data => {
                    resolve(data);
                });
        });
    }

    public async getTemas() : Promise<ITema[]> {
        this.updateAuthorizationHeader();
        return new Promise<ITema[]>((resolve, reject) => {
            fetch(`${this.urlBasica}v1/portal-conhecimento/custom/temas`, this.options)
                .then(response => response.json() as Promise<IRegistros<ITema>>)
                .then(data => {
                    resolve(data.registros);
                });
        });
    }

    public async getAvatar(): Promise<string> {
        this.updateAuthorizationHeader();
        return new Promise<string>((resolve, reject) => {
            fetch('http://ms-aria.appsdev.ocp.tesouro.gov.br/v1/portal-conhecimento/custom/avatar', this.options)
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    resolve(data.urlAvatar);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}