const useUrlBasica = () => {
    // Se a url do navegador for o localhost, usar http://ms-aria.appsdev.ocp.tesouro.gov.br/
    // Senão, usar https://apiapex.tesouro.gov.br/aria/
    if(window.location.href.includes('localhost')) {
        return 'http://ms-aria.appsdev.ocp.tesouro.gov.br/';
    } else {
        return 'https://apiapex.tesouro.gov.br/aria/';
    }    
}

export default useUrlBasica;