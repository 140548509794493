import React, { useEffect } from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate
} from "react-router-dom";
import Principal from './pages/Principal/Principal';
import CatalogoComponentes from './pages/CatalogoComponentes/CatalogoComponentes';
import DetalheComponente from './pages/CatalogoComponentes/DetalheComponente/DetalheComponente';
import Busca from './pages/Busca/Busca';
import Sobre from './pages/Sobre/Sobre';
import Documentacoes from './pages/Documentacoes/Documentacoes';
import Comunidade from './pages/Comunidade/Comunidade';
import { AuthProvider } from 'react-oidc-context';
import CategoriaDoc from './pages/Documentacoes/CategoriaDoc/CategoriaDoc';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import Layout from './components/Layout/Layout';
import Copilot from './pages/Copilot/Copilot';

function App() {

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <>
            <Principal />
          </>,
        },
        {
          path: "/catalogo-componentes",
          element: <>
            <CatalogoComponentes />
          </>,
        },
        {
          path: "/catalogo-componentes/:codigo",
          element: <>
            <DetalheComponente  />
          </>,
        },
        {
          path: "/busca",
          element: <>
            <Busca  />
          </>,
        },
        {
          path: "/sobre",
          element: <>
            <Sobre  />
          </>,
        },
        {
          path: "/docs",
          element: <>
            <Documentacoes  />
          </>,
        },
        {
          path: "/docs/:categoria",
          element: <>
            <CategoriaDoc />
          </>,
        },
        {
          path: "/docs/:categoria/:artigo/:url*",
          element: <>
            <CategoriaDoc />
          </>,
        },
        {
          path: "/comunidade",
          element: <>
            <Comunidade  />
          </>,
        },
        {
          path: "/copilot",
          element: <>
            <Copilot />
          </>
        }
      ]
    }
    
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get('state');
    const sessionState = urlParams.get('session_state');

    if (state && sessionState) {
      // Lógica de login aqui

      // Remove os parâmetros da URL
      urlParams.delete('state');
      urlParams.delete('session_state');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
    }
  }, []);

  const userManagerSettings : any = {
    authority: 'https://sso.tesouro.gov.br/auth/realms/interno',
    client_id: 'cliente-aria',
    redirect_uri: window.location.href
  }

  return (
    <AuthProvider {...userManagerSettings} >
      <React.StrictMode>
        <RecoilRoot>
          <RouterProvider router={router} />
          <ToastContainer />
        </RecoilRoot>
      </React.StrictMode>
    </AuthProvider>
  );
}

export default App;
