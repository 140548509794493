import { Breadcrumb, Button, Col, Container, Row } from "react-dsgov";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { IResultadosBusca } from "../../interfaces/IResultadoBusca";
import styles from './Busca.module.scss'
import useGeraLink from "../../hooks/useGeraLink";
import useCatalogoService from "../../hooks/useCatalogoService";
import InfiniteScroll from "react-infinite-scroll-component";
import { iswitch } from "iswitch";
import { Helmet } from "react-helmet";
import { useAuth } from "react-oidc-context";

const Busca : React.FC = () => {
    const catalogoService = useCatalogoService();
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [resultado, setResultado] = useState<IResultadosBusca>();

    // Paginação
    const [pagina, setPagina] = useState<number>(1);
    const [carregouTodos, setCarregouTodos] = useState<boolean>(false);

    const geraLink = useGeraLink();

    const auth = useAuth();

    useEffect(() => {
        
        const termo = searchParams.get("termo");

        if(termo) {
            catalogoService.busca(termo).then(resultados => setResultado(resultados));
        } else {
            setResultado(undefined);
        }
    }, [catalogoService, searchParams]);

    // Carregar mais componentes, para o infinite scroll
    const carregarMais = () => {
        const termo = searchParams.get("termo");
        if(termo) {
            catalogoService.busca(termo, pagina + 1).then(resultadoBusca => {
                if(resultadoBusca.registros.length === 0) {
                    setCarregouTodos(true);
                } else {
                    setPagina((oldPagina) => {
                        const novaPagina = oldPagina + 1;
            
                        if(resultadoBusca && resultadoBusca.registros) {
                            setResultado((oldResultadoBusca) => {
                                if(oldResultadoBusca?.registros) {
                                    oldResultadoBusca.registros = [...oldResultadoBusca?.registros, ...resultadoBusca.registros];
                                }
                                

                                return oldResultadoBusca;
                            })
                        }                
            
                        return novaPagina;
                    })
                }
    
                
            });
        }
        
    }


    return (
        <Container className={styles.pagina}>
            <Helmet>
                <title>Resultado de busca por {searchParams.get("termo")}</title>
            </Helmet>
            <Breadcrumb>
                <Breadcrumb.Item home href="#" onClick={() => navigate("/")}></Breadcrumb.Item>
                <Breadcrumb.Item>Busca</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    {resultado?.registros && resultado.registros.length > 0 && <div className={styles['descricao-resultado']}>A seguir, os resultados da busca para o termo "{searchParams.get("termo")}":</div>}
                    {(!resultado?.registros || resultado.registros.length === 0) && <div className={styles['descricao-resultado']}>Não foram encontrados resultados para o termo "{searchParams.get("termo")}".</div>}
                </Col>
            </Row>
            <Row>
                <Col>
                    {resultado?.registros && <InfiniteScroll
                        dataLength={resultado?.registros.length}
                        next={carregarMais}
                        hasMore={!carregouTodos}
                        loader={<div className="d-flex justify-content-center"><Button primary onClick={carregarMais}>Carregar mais</Button></div>}
                    >
                        {resultado?.registros?.map((item, index) => 
                            <div key={index} className={styles.resultado}>
                                <div className={styles.titulo}><Link to={geraLink(item.codigo, item.tipo)}>{item.nome} <i className="fas fa-external-link-square-alt"></i></Link></div>
                                <div className={styles.subtitulo}>{iswitch(item.tipo, [['AU', 'AH', 'AM'], () => 'Artigo'], ['C', () => 'Componente'])}</div>
                                <div className={styles.corpo}>{item.trecho}</div>
                            </div>
                        )}
                    </InfiniteScroll>}
                </Col>
            </Row>

            {!auth.isAuthenticated && <Row mt="5">
                <Col mt="5">
                    <div><b>Atenção:</b> Autentique para obter resultados mais completos.</div>
                    <Button mt="2" primary onClick={() => auth.signinRedirect({redirect_uri: window.location.href})}><i className="fas fa-user" aria-hidden="true"></i> Clique aqui para entrar</Button>
                </Col>
            </Row>   
            }
            
        </Container>
    );
}

export default Busca;