import { Item, List } from "react-dsgov";
import { Link } from "react-router-dom";
import ICategoriaDocumentacao from "../../../interfaces/ICategoriaDocumentacao";
import classNames from "classnames";
import styles from "./MenuLateralCategorias.module.scss";

export interface MenuLateralCategoriasProps {
    categorias : ICategoriaDocumentacao[]
}

const MenuLateralCategorias : React.FC<MenuLateralCategoriasProps> = ({categorias}) => {
    return (
        <List>
            {categorias?.map(categoria => 
                <Item showDividerAfter collapsable initiallyExpanded
                    key={categoria.id}
                    subItems={<>{categoria.artigos.sort((a, b) => Number(b.autorizado) - Number(a.autorizado)).map(artigo =>
                        <>{artigo.codigo && <Item className={classNames({[styles.inativo] : !artigo.autorizado})} key={artigo.id}><Link to={`/docs/${artigo.codigo}`}>{artigo.nome}</Link></Item>}</>
                    )}</>}
                ><b>{categoria.nome}</b></Item>    
            )}
        </List>
    )
}

export default MenuLateralCategorias;